import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import image1 from "../../../assets/images/blog/icon-open-ai/icon-open-ai.png"
import video1 from "../../../assets/videos/icon-open-ai/video1.mp4"
import video2 from "../../../assets/videos/icon-open-ai/video2.mp4"


export default () => (
  <div>
    <SEO
      title={"Drupal OpenAI ChatGPT Integration"}
      description="Drupal OpenAI ChatGPT Integration"
      canonical={"https://icon-worldwide.com/news/ChateGPT-Drupal"}
      image={"https://icon-worldwide.com/social_images/icon-open-ai.png"}
    />

    <NewsHeader />

    <div id="single-news">
      <h1>
        ICON Worldwide Develops <span>Drupal OpenAI ChatGPT</span> Integration.
      </h1>
      <div id="title-underline"></div>
      <div id="single-news-texts">
        <img
          src={image1}
          alt="OpenAI ChatGPT Integration"
          title="OpenAI ChatGPT Integration"
          style={{ width: "100%", display: "block", margin: "0 0 30px" }}
        />

       <h2>ICON Worldwide Develops Drupal OpenAI Integration</h2>

        <p>
          OpenAI and ChatGPT have dominated the discussion this year from 
          multiple perspectives including social and economic implications.
          2023 will be remembered as the year that OpenAI, ChatGPT and AI 
          entered the mainstream consciousness.
        </p>

        <h2>Developers Love OpenAI and ChatGPT</h2>

        <p>
        Perhaps no single group has been more enthusiastic than the developer 
        community who appreciate the real technological leap that Chat GPT represents.
        </p>

        <p>
        The immediate release of APIs for developers was a huge signal that ChatGPT 
        is committed to expanding the application as broadly as possible. By providing 
        open APIs, developers are invited to actively participate in enriching the 
        broader ecosystem through the creation of novel applications for Chat GPT. 
        This strategic API release demonstrates a robust willingness to collaborate 
        with the wider developer community, ushering in a wave of fresh ideas and
        wider adoption of Chat GPT. For more on the ChatGPT APIs see: <br /><br />
        <strong><a href="https://www.wired.com/story/chatgpt-api-ai-gold-rush/"> https://www.wired.com/story/chatgpt-api-ai-gold-rush/</a></strong>
        </p>

        <p>
        As Drupal developers, we naturally began thinking about how to integrate
        Chat GPT into the Drupal CMS. We immediately began to test the API and 
        considered ways to bring this technology into our daily business, and into
        our clients' work.  
        </p>

        <h2>ICON Drupal ChatGPT Plugin</h2>

        <p>
        We’ve developed a seamless integration of ChatGPT into the Drupal CMS.  
        This integration provides massive opportunities to improve how you 
        work with Drupal in two main ways:
        </p>

        <h3>1. Website content development and translation</h3>

        <p>Use ChatGPT to create website content for new pages, or to support
           the text you’ve already written.  Simply ask ChatGPT for the text 
           you need, copy and paste it into your page.
        </p>
      
        <video poster={video1} controls>
            <source src={video1} type="video/mp4"></source>
        </video>

      <h3>2. Technical support working with Drupal.</h3>
      
      <p>
        Many users struggle with basic Drupal modules, modifications or even
        formatting.  Stumped on how to proceed, just click on the ChatGPT 
        icon and place your question.
      </p>

      <video poster={video2} controls>
            <source src={video2} type="video/mp4"></source>
      </video>


      <p>To try the plugin on your Drupal website, download the code here: <br /><br />
      <strong><a href="https://github.com/iconworldwide/IconWorldwide_OpenAiChatGPT"> https://github.com/iconworldwide/IconWorldwide_OpenAiChatGPT/</a></strong>  and follow the instructions in the txt file.
      </p>

      <p><strong>The plugin is in Beta so please feel free to give us 
        feedback on bugs, improvements, or new ideas.</strong>
      </p>

      <p>We hope to hear from you soon!</p>

      </div>

      <img
        src={arrow}
        id="single-news-horizontal-arrow"
        alt="ICON Worldwide arrow"
        title="ICON Worldwide arrow"
      />
      <h4>
        <Link to="/contact-us" className="cta">
          CONTACT US
        </Link>
      </h4>

      <NewsFooter
        previous="zielabi-app-development-case-study"
        next="drupal10"
      />
    </div>

    <Footer noScrollbar="true" />
  </div>
)
